// Here you can add other styles
.btn-primary {
  background-color: #e55353;
  border-color: #e55353;
}
.btn.btn-primary:hover {
  background-color: #e55353;
  border: none;
}
.btn.btn-primary:hover::after {
  background-color: #e55353;
}
.btn.btn-primary:active {
  background-color: #e55353;
  border-color: #e55353;
}
.btn.btn-primary:focus {
  background-color: #e55353;
  border-color: #e55353;
  box-shadow: 0 0 0 0.25rem rgb(239 96 96 / 87%);
}
.btn.btn-primary:active:focus,
.btn.btn-primary.show:focus {
  box-shadow: 0 0 0 0.25rem rgb(239 96 96 / 87%);
}
button:focus:not(:focus-visible) {
  outline: 0;
}

.img-circule-bg {
  background: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: relative;
}
.img-circule {
  position: absolute;
  top: 5px;
  left: 5px;
}
.myTable {
  position: absolute;
}
.table-row {
  min-height: 55vh;
  position: relative;
}

.background-radial-gradient {
  background-color: hsl(218, 41%, 15%);
  background-image: radial-gradient(
      650px circle at 0% 0%,
      hsl(218, 41%, 35%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%
    ),
    radial-gradient(
      1250px circle at 100% 100%,
      hsl(218, 41%, 45%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%
    );
}

#radius-shape-1 {
  height: 220px;
  width: 220px;
  top: -60px;
  left: -130px;
  background: radial-gradient(#44006b, #ad1fff);
  overflow: hidden;
}

#radius-shape-2 {
  border-radius: 38% 62% 63% 37% / 70% 33% 67% 30%;
  bottom: -60px;
  right: -110px;
  width: 300px;
  height: 300px;
  background: radial-gradient(#44006b, #ad1fff);
  overflow: hidden;
}

.bg-glass {
  background-color: hsla(30, 2%, 22%, 0.638) !important;
  backdrop-filter: saturate(200%) blur(25px);
}

.app-bg-inactive {
  background-color: #f11a2e;
}

.app-bg-active {
  background-color: #199603;
}

.app-bg-top {
  background-color: #eef42b;
  color: #000 !important;
}

.app-bg-2 {
  background-color: #2b2b2a;
}

.tree-content {
  border-radius: 10px;
  color: #fff;
  padding: 5px;
  font-size: 10px;
  font-weight: 700;
}

.divScroll {
  overflow-y: scroll;
}

.modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  height: 100%;
  right: 0px;
}
.modal-content {
  height: 100%;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 93%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

ul.timeline > li.achive:before {
  content: ' ';
  background: rgb(2, 94, 10);
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid rgb(1, 52, 6);
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}
ul.timeline > li.achive p{
  text-decoration: underline;
}

